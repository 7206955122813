<template>

  <div class="checkout-success rel-container">
    <v-layout class="pos-rel" fill-height align-center justify-center>

      <div class="text-xs-center">

        <v-progress-circular
          indeterminate
          :size="28"
          color="grey darken-1"
        ></v-progress-circular>
        <div class="pt-1 body-1 grey--text text--darken-1">
          Checkout Successful
        </div>
        <div class="pt-2 caption grey--text">
          Please wait while we create your gifts.
        </div>

      </div>

      <div class="pos-abs text-xs-center pa-1 caption grey--text" style="bottom:0; left:0; right:0;">
        If you experience checkout issues, please email us at <a style="color:#BBB;" href="mailto:support@swipewrap.com">support@swipewrap.com</a>
      </div>

    </v-layout>
  </div>

</template>


<script>

export default {
  name:'checkout-success',
  components: {
  },
  data() {
    return {
    }
  },
  computed: {
  },
  methods: {
  }
}
</script>

<style scoped>

/* .checkout-success {
  background-color: #B03060;
} */

</style>
